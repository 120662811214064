<template>
  <div>
    <div class="bj">
      <div class="bjContent">
        <div class="bjCont">
          <div class="iconfont icon-close cha" @click="delClick"></div>
          <div class="cont">
            <div class="contHead">注册</div>
            <div class="contBox">
              <div class="name"><span>*</span>手机账号</div>
              <div class="inpBox">
                <a-input placeholder="请输入手机账号" v-model="mobile" />
              </div>
            </div>
            <div class="contBox">
              <div class="name"><span>*</span>验证码</div>
              <div class="inpBox">
                <a-input placeholder="请输入验证码" v-model="yanzheng" />
                <div class="yzmBox" v-if="!yzmShow" @click="yzmClick">
                  获取验证码
                </div>
                <div class="yzmBox anxia" v-if="yzmShow">{{ time }}</div>
              </div>
            </div>
            <div class="contBox">
              <div class="name"><span>*</span>用户昵称</div>
              <div class="inpBox">
                <a-input placeholder="请输入用户昵称" v-model="name" />
              </div>
            </div>
            <div class="contBox">
              <div class="name"><span>*</span>密码</div>
              <div class="inpBox">
                <a-input-password placeholder="请输入密码" v-model="password" />
              </div>
            </div>
            <div class="contBox">
              <div class="name"><span>*</span>重复密码</div>
              <div class="inpBox">
                <a-input-password
                  placeholder="请再次输入密码"
                  v-model="newpassword"
                />
              </div>
            </div>
          </div>
          <div class="gnBox">
            <div class="btn qx" @click="delClick">取消</div>
            <div class="btn" @click="saveclick">注册</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Select, Form, Input, Button, Upload, Icon } from "ant-design-vue";
export default {
  components: {
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-form": Form,
    "a-form-item": Form.Item,
    "a-input": Input,
    "a-input-password": Input.Password,
    "a-button": Button,
    "a-textarea": Input.TextArea,
    "a-upload": Upload,
    "a-icon": Icon,
  },
  data() {
    return {
      size: "default",
      value: "",
      headers: {
        authorization: "authorization-text",
      },
      form: {
        username: "",
        password: "",
      },
      mobile: "",
      yanzheng: "",
      name: "",
      password: "",
      newpassword: "",
      time: 60,
      yzmShow: false,
    };
  },
  mounted() {},
  activated() {},
  methods: {
    yzmClick() {
      if (!this.mobile) {
        this.$message.error('请先输入手机号');
        return
      }
      this.yzmShow = true;
      this.timeYs = setInterval(() => {
        if (this.time == 0) {
          this.yzmShow = false;
          this.time = 60
          clearInterval(this.timeYs);
        } else {
          this.time--;
        }
      }, 1000);
      this.$post("/common/sms_send", {
        mobile: this.mobile,
      }).then((res) => {
        console.log(res);
        if (res.data.status == 1) {
          this.$message.success("发送成功");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    saveclick() {
      if (!this.mobile) {
        this.$message.error("请输入手机账号");
        return;
      }
      if (!this.name) {
        this.$message.error("请输入用户昵称");
        return;
      }
      if (!this.password) {
        this.$message.error("请输入密码");
        return;
      }
      if (!this.newpassword) {
        this.$message.error("请输入确认密码");
        return;
      }
      if (this.password != this.newpassword) {
        this.$message.error("密码需要和确认密码相同");
        return;
      }
      this.$post("/web/register", {
        mobile: this.mobile,
        name: this.name,
        code: this.yanzheng,
        password: this.password,
      }).then((res) => {
        console.log(res);
        if (res.data.status == 1) {
          this.$message.success("注册成功");
          this.$emit("show", { title: "" });
          setTimeout(() => {
            this.$router.push({
              path: "/login",
              query: {},
            });
          }, 500);
        } else {
          this.$message.success(res.data.msg);
        }
      });
    },
    delClick() {
      this.$emit("show", { title: "" });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
    handleChange(value) {
      console.log(`Selected: ${value}`);
    },
    popupScroll() {
      console.log("popupScroll");
    },
  },
};
</script>
<style lang="less" scoped>
.bjContent {
  display: flex;
  width: 100%;
  justify-content: center;

  .bjCont {
    background-color: #fff;
    width: 600px;
    margin-top: 90px;
    border-radius: 10px;
    position: relative;

    .cha {
      position: absolute;
      cursor: pointer;
      top: 10px;
      font-weight: 600;
      right: 20px;
      color: #9c9c9c;
    }

    .cont {
      padding: 20px 20px 10px;
      .peoImg {
        text-align: center;
        img {
          object-fit: cover;
          overflow: hidden;
          border-radius: 50%;
          width: 70px;
          height: 70px;
          cursor: pointer;
        }
      }

      .contHead {
        font-size: 16px;
        font-weight: 600;
        color: #333;
      }

      .contBox {
        padding-top: 20px;
        display: flex;
        .name {
          margin-top: 5px;
          width: 90px;
          text-align: right;
          padding-right: 10px;
          box-sizing: border-box;
          font-size: 12px;
          span {
            color: #f30000;
          }
        }
        .inpBox {
          width: calc(100% - 90px);
          display: flex;
          position: relative;
          .yzmBox {
            position: absolute;
            top: 1px;
            right: 1px;
            width: 80px;
            height: calc(100% - 2px);
            background-color: #ce0808;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 12px;
          }
          .anxia {
            background-color: #999;
          }
          .box {
            margin-right: 15px;
            width: calc(33.33% - 10px);
            float: left;
          }
          .box:last-child {
            margin-right: 0;
          }
        }

        .tishi {
          line-height: 150%;
          color: #bfbfbf;
          font-size: 12px;
          margin-top: 5px;
          text-align: justify;
        }

        .upImg {
          margin-top: 10px;

          .text {
            margin-left: 20px;
            font-size: 12px;
          }

          .txt {
            margin-left: 5px;
            font-size: 12px;
            color: #999;
          }

          .imgs {
            display: flex;
            margin-top: 15px;
            flex-wrap: wrap;

            .img {
              width: calc(20% - 12px);
              margin-right: 15px;
              padding-top: calc(20% - 12px);
              position: relative;
              margin-bottom: 15px;
              overflow: unset;

              img {
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 5px;
              }

              .close {
                position: absolute;
                top: -3px;
                right: -3px;
                background-color: #b3b3b3;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 10px;
                color: #fff;
              }
            }

            .img:nth-child(5n) {
              margin-right: 0;
            }
          }
        }
      }
    }

    .gnBox {
      border-top: 1px solid #d9d9d9;
      padding: 10px 24px;
      overflow: hidden;
      display: flex;
      justify-content: flex-end;

      .btn {
        color: #fff;
        background: #e23;
        border: 1px solid #e23;
        padding: 5px 15px;
        font-size: 12px;
        border-radius: 5px;
        margin-left: 15px;
        cursor: pointer;
      }

      .qx {
        background: unset;
        border: 1px solid rgb(180, 180, 180);
        color: #666;
      }
    }
  }
}
</style>
