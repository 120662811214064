import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts';
import { get, post, uploadfile, baseurl } from './assets/js/ajax'
import 'swiper/dist/css/swiper.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import Button from 'ant-design-vue/lib/button';
import Popconfirm from 'ant-design-vue/lib/popconfirm';
import Pagination from 'ant-design-vue/lib/pagination';
import Carousel from 'ant-design-vue/lib/carousel';
import alert from 'ant-design-vue/lib/alert';
import message from 'ant-design-vue/lib/message';
import moment from 'moment'
import 'ant-design-vue/dist/antd.css';
import VueMeta from 'vue-meta';

Vue.component(Popconfirm.name, Popconfirm);
Vue.component(Pagination.name, Pagination);
Vue.component(Button.name, Button);
Vue.component(alert.name, alert);
Vue.component(Carousel.name, Carousel);
Vue.use(VueAwesomeSwiper)
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false

// ajax
Vue.prototype.$get = get
Vue.prototype.$post = post
Vue.prototype.$uploadfile = uploadfile
Vue.prototype.$message = message
Vue.prototype.$url = baseurl
// Vue.prototype.$imgUrl = 'https://rent.tzgcs360.com'
Vue.prototype.$imgUrl = 'https://suzkj.com'
Vue.prototype.$moment = moment

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

Vue.use(VueMeta, {
  // 配置项
  keyName: 'metaInfo', // 组件中用来定义元信息的属性名
});


new Vue({
  router,
  store,
  render: h => h(App),
  // 预渲染
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
