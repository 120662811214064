<template>
  <div>
    <div class="bj">
      <div class="content">
        <div class="headBox">
          <div class="name">上传/编辑头像</div>
          <div class="iconfont icon-close cha" @click="delClick"></div>
        </div>
        <div class="cont">
          <div class="img" v-if="!imgUp" @click="uploadFile">
            <div class="iconfont icon-shangchuan- shangchuan"></div>
            <div class="text">点击上传头像</div>
          </div>
          <div class="img" v-if="imgUp">
            <img :src="$imgUrl + imgUp" alt="" />
          </div>
        </div>
        <div class="btnBox">
          <div class="dqBox">
            <div class="title">当前头像</div>
            <div class="img">
              <img
                :src="img ? $imgUrl + img : $imgUrl + user.avatar"
                alt=""
                srcset=""
              />
            </div>
          </div>
          <div class="xzBox">
            <div class="title">选择头像</div>
            <div class="box">
              <div
                class="img"
                :class="img == item ? 'on' : ''"
                v-for="item in list"
                @click="imgClick(item)"
              >
                <img :src="$imgUrl + item" />
              </div>
            </div>
            <div class="txt">注:可以选择系统头像替换当前头像</div>
          </div>
        </div>
        <div class="anBox">
          <div class="scBtn dis-cent" @click="saveClick">保存</div>
          <div class="qxBtn dis-cent" @click="delClick">取消</div>
        </div>
      </div>
    </div>
    <input
      type="file"
      ref="fileInput"
      @change="onFileChange"
      style="display: none"
    />
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      img: "",
      list: [
        "/static/avatar/tx1.png",
        "/static/avatar/tx2.png",
        "/static/avatar/tx3.png",
        "/static/avatar/tx4.png",
      ],
      imgUp: "",
    };
  },
  props: ["user"],
  created() {},
  methods: {
    delClick() {
      this.$emit("show");
    },
    imgClick(e) {
      this.img = e;
    },
    onFileChange(e) {
      var formData = new FormData();
      console.log(e.target.files);
      formData.append("file", e.target.files[0]);
      formData.append("where", "product");
      this.$uploadfile("/common/fileupload", formData).then((res) => {
        console.log(res);
        if (res.data.status) {
          this.imgUp = res.data.data.path_name;
        } else {
          this.$message.error("网络繁忙,请稍后再试~");
        }
      });
    },
    async uploadFile() {
      this.$refs.fileInput.click();
    },
    saveClick() {
      this.$emit("show", { img: this.imgUp ? this.imgUp : this.img });
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.bj {
  display: flex;
  justify-content: center;
  .content {
    background-color: #fff;
    width: 600px;
    margin-top: 90px;
    height: 600px;
    border-radius: 10px;
    position: relative;
    .headBox {
      border-bottom: 1px solid #ebebeb;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      .name {
        font-size: 18px;
        font-weight: 600;
      }
      .cha {
        font-size: 20px;
        font-weight: 600;
        color: #9c9c9c;
        cursor: pointer;
      }
    }
    .cont {
      height: 300px;
      border-bottom: 1px solid #ebebeb;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .img {
        width: 220px;
        height: 220px;
        border-radius: 10px;
        border: 1px dashed #c9c9c9;
        background-color: #f1f1f1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #666;
        cursor: pointer;
        .shangchuan {
          font-size: 31px;
          font-weight: 600;
          color: #9c9c9c;
        }
      }
    }
    .btnBox {
      padding: 20px 40px;
      display: flex;
      .dqBox {
        width: 70px;
      }
      .xzBox {
        width: calc(100% - 70px);
        padding-left: 50px;
        .txt {
          font-size: 12px;
          color: #9c9c9c;
          margin-top: 5px;
        }
      }
      .title {
        font-size: 16px;
      }
      .img {
        margin-top: 20px;
      }
      .box {
        display: flex;
        .img {
          width: 70px;
          margin-right: 15px;
          border: 2px solid #fff;
          cursor: pointer;
          overflow: hidden;
        }
        .on {
          border: 2px solid #147aff;
        }
      }
    }
    .anBox {
      display: flex;
      align-items: center;
      justify-content: center;
      .scBtn {
        width: 80px;
        height: 35px;
        border-radius: 35px;
        background-color: #f32004;
        color: #fff;
        margin-right: 15px;
        cursor: pointer;
      }
      .qxBtn {
        width: 80px;
        height: 35px;
        border-radius: 35px;
        border: 1px solid #ebebeb;
        cursor: pointer;
      }
    }
  }
}
</style>
