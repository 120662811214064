<template>
  <div>
    <div class="bj">
      <div class="bjContent">
        <div class="bjCont">
          <div class="iconfont icon-close cha" @click="delClick"></div>
          <div class="cont">
            <div class="peoImg">
              <div class="img">
                <img :src="$imgUrl + user.avatar" @click="phoUpShow = true" />
              </div>
              <div class="box">
                <div class="name" @click="phoUpShow = true">上传头像</div>
                <div class="text">自己风格的头像会让大家对你有更多好感</div>
              </div>
            </div>
            <div class="contBox">
              <div class="name">昵称</div>
              <div class="inpBox">
                <a-input placeholder="请输入昵称" v-model="user.nickname" />
              </div>
            </div>
            <div class="contBox">
              <div class="name"><span>*</span>真实姓名</div>
              <div class="inpBox">
                <a-input placeholder="请输入真实姓名" v-model="user.name" />
              </div>
            </div>
            <div class="contBox">
              <div class="name"><span>*</span>工作单位</div>
              <div class="inpBox">
                <a-input
                  placeholder="请输入工作单位"
                  v-model="user.company_name"
                />
              </div>
            </div>
            <div class="contBox">
              <div class="name">岗位</div>
              <div class="inpBox">
                <a-input placeholder="请输入岗位" v-model="user.position" />
              </div>
            </div>
            <div class="contBox">
              <div class="name">所在地理位置</div>
              <div class="inpBox">
                <div class="box">
                  <a-input placeholder="请输入省" v-model="user.province" />
                </div>
                <div class="box">
                  <a-input placeholder="请输入市" v-model="user.city" />
                </div>
                <div class="box">
                  <a-input placeholder="请输入区" v-model="user.area" />
                </div>
              </div>
            </div>
            <div class="contBox">
              <div class="name">自我描述</div>
              <div class="inpBox">
                <a-textarea
                  placeholder="请输入自我描述"
                  style="height: 160px"
                  :rows="4"
                  v-model="user.signature"
                />
              </div>
            </div>
          </div>
          <div class="gnBox">
            <div class="btn qx" @click="delClick">取消</div>
            <div class="btn" @click="saveClick">提交</div>
          </div>
        </div>
      </div>
    </div>
    <PhoUp v-if="phoUpShow" :user="user" @show="pUpShowJs" />
    <input
      type="file"
      ref="fileInput"
      @change="onFileChange"
      style="display: none"
    />
  </div>
</template>
<script>
import { Select, Form, Input, Button, Upload, Icon } from "ant-design-vue";
import PhoUp from "./phoUp.vue";
import { data } from "jquery";
export default {
  components: {
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-form": Form,
    "a-form-item": Form.Item,
    "a-input": Input,
    "a-button": Button,
    "a-textarea": Input.TextArea,
    "a-upload": Upload,
    "a-icon": Icon,
    PhoUp,
  },
  data() {
    return {
      size: "default",
      value: "",
      headers: {
        authorization: "authorization-text",
      },
      form: {
        username: "",
        password: "",
      },
      user: {},
      phoUpShow: false,
    };
  },
  mounted() {
    this.get();
  },
  activated() {},
  methods: {
    pUpShowJs(data) {
      if (data) {
        this.user.avatar = data.img;
      }
      this.phoUpShow = false;
    },
    get() {
      this.$get("/web/member").then((res) => {
        window.localStorage.setItem("member", JSON.stringify(res.member));
        this.user = res.member;
      });
    },
    onFileChange(e) {
      var formData = new FormData();
      console.log(e.target.files);
      formData.append("file", e.target.files[0]);
      formData.append("where", "product");
      this.$uploadfile("/common/fileupload", formData).then((res) => {
        console.log(res);
        if (res.data.status) {
          this.user.avatar = res.data.data.path_name;
        } else {
          this.$message.error("网络繁忙,请稍后再试~");
        }
      });
    },
    async uploadFile() {
      this.$refs.fileInput.click();
    },
    delClick() {
      this.$emit("show");
    },
    saveClick() {
      let data = this.user;
      if (!data.name) {
        this.$message.error("请输入真实姓名");
        return;
      }
      if (!data.company_name) {
        this.$message.error("请输入工作单位");
        return;
      }
      data = JSON.stringify(data);
      this.$post("/web/member_save", {
        data: data,
      }).then((res) => {
        console.log(res);
        if (res.data.status) {
          this.$message.success("修改成功");
          this.$emit("show", { tyep: 1 });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
    handleChange(value) {
      console.log(`Selected: ${value}`);
    },
    popupScroll() {
      console.log("popupScroll");
    },
  },
};
</script>
<style lang="less" scoped>
.bjContent {
  display: flex;
  width: 100%;
  justify-content: center;

  .bjCont {
    background-color: #fff;
    width: 600px;
    margin-top: 90px;
    border-radius: 10px;
    position: relative;

    .cha {
      position: absolute;
      cursor: pointer;
      top: 10px;
      font-weight: 600;
      right: 20px;
      color: #9c9c9c;
    }

    .cont {
      padding: 20px 20px 10px;
      .peoImg {
        display: flex;
        align-items: center;
        padding-left: 90px;
        // justify-content: center;
        .img {
          border-radius: 50%;
          width: 80px;
          height: 80px;
          img {
            object-fit: cover;
            overflow: hidden;
            cursor: pointer;
          }
        }
        .box {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 80px;
          padding: 5px 0;
          padding-left: 10px;
          .name {
            font-size: 20px;
            font-weight: 600;
          }
          .text {
            font-size: 12px;
            color: #9c9c9c;
          }
        }
      }

      .contHead {
        font-size: 16px;
        font-weight: 600;
        color: #333;
      }

      .contBox {
        padding-top: 20px;
        display: flex;
        .name {
          margin-top: 5px;
          width: 90px;
          text-align: right;
          padding-right: 10px;
          box-sizing: border-box;
          font-size: 12px;
          span {
            color: #f30000;
          }
        }
        .inpBox {
          width: calc(100% - 90px);
          display: flex;
          .box {
            margin-right: 15px;
            width: calc(33.33% - 10px);
            float: left;
          }
          .box:last-child {
            margin-right: 0;
          }
        }

        .tishi {
          line-height: 150%;
          color: #bfbfbf;
          font-size: 12px;
          margin-top: 5px;
          text-align: justify;
        }

        .upImg {
          margin-top: 10px;

          .text {
            margin-left: 20px;
            font-size: 12px;
          }

          .txt {
            margin-left: 5px;
            font-size: 12px;
            color: #999;
          }

          .imgs {
            display: flex;
            margin-top: 15px;
            flex-wrap: wrap;

            .img {
              width: calc(20% - 12px);
              margin-right: 15px;
              padding-top: calc(20% - 12px);
              position: relative;
              margin-bottom: 15px;
              overflow: unset;

              img {
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 5px;
              }

              .close {
                position: absolute;
                top: -3px;
                right: -3px;
                background-color: #b3b3b3;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 10px;
                color: #fff;
              }
            }

            .img:nth-child(5n) {
              margin-right: 0;
            }
          }
        }
      }
    }

    .gnBox {
      border-top: 1px solid #d9d9d9;
      padding: 10px 24px;
      overflow: hidden;
      display: flex;
      justify-content: flex-end;

      .btn {
        color: #fff;
        background: #e23;
        border: 1px solid #e23;
        padding: 5px 15px;
        font-size: 12px;
        border-radius: 5px;
        margin-left: 15px;
        cursor: pointer;
      }

      .qx {
        background: unset;
        border: 1px solid rgb(180, 180, 180);
        color: #666;
      }
    }
  }
}
</style>
