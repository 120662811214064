<template>
  <div>
    <div class="content dis-cent">
      <div
        class="iconfont icon-lianxiwomen_lianxikefu lianxiwomen_lianxikefu"
      ></div>
      <div class="name">联系我们</div>
      <div class="tanchu">
        <div class="box" @click="fzClick(18767647976)">
          商城服务:18767647976
        </div>
        <div class="box" @click="fzClick(18767422026)">
          设备维修:18767422026
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {
    fzClick(tel) {
      const textarea = document.createElement("textarea");
      textarea.value = tel;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      this.$message.success("复制电话成功");
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.content {
  position: fixed;
  bottom: 200px;
  right: 50px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 4px 2px #ebebeb;
  width: 70px;
  height: 70px;
  flex-direction: column;
  font-size: 12px;
  color: #666;
  cursor: pointer;
  .lianxiwomen_lianxikefu {
    font-size: 24px;
  }
  .name {
    margin-bottom: 5px;
  }
  .tanchu {
    position: absolute;
    top: 70px;
    right: -40px;
    padding: 10px;
    width: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    font-size: 14px;
    border: 1px solid #ebebeb;
    display: none;
    .box {
      margin-bottom: 5px;
    }
    .box:last-child {
      margin-bottom: 0;
    }
  }
}
.content:hover {
  .tanchu {
    display: flex;
  }
}
</style>
