<template>
  <div id="app">
    <!-- <MrHeadBox /> -->
    <head-box @wenzhangShow="wenzhangShow" v-if="$route.meta.hidden" />
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :key="key" />
    <buttom-box v-if="!$route.meta.dihidden" :yemian="yemian" />
    <Wenzhang v-if="wzshow" @show="wenzhangShowJs" />
    <Lianxi />
  </div>
</template>

<style>
@import "./assets/css/base.css";
</style>
<script>
import ButtomBox from "./views/component/buttomBox.vue";
import headBox from "./views/component/headBox.vue";
import Lianxi from "./views/component/lianxi.vue";
import MrHeadBox from "./views/component/mrHeadBox.vue";
import Wenzhang from "./views/component/wenzhang.vue";
export default {
  components: { headBox, ButtomBox, Wenzhang, MrHeadBox, Lianxi },
  data() {
    return {
      listIndex: 0,
      list: [],
      wzshow: false,
      yemian: "",
    };
  },
  watch: {
    $route(val) {
      this.yemian = val.fullPath;
    },
  },
  computed: {
    key() {
      return this.$route.fullPath;
    },
  },
  methods: {
    // 文章
    wenzhangShow() {
      this.wzshow = true;
    },
    wenzhangShowJs() {
      this.wzshow = false;
    },
  },
};
</script>
