import axios from 'axios'
import Qs from 'qs'
import store from '../../store/index'
import router from '../../router'
import { Toast } from 'vant';
import message from 'ant-design-vue/lib/message';

// axios.defaults.withCredentials = true
axios.defaults.crossDomain = true
// export const baseurl = 'http://localhost:8101/api/admin'
let baseurlUrl = ''
if (window.location.href.indexOf('localhost') != -1 || window.location.href.indexOf('192.168') != -1) {
  baseurlUrl = "http://192.168.130.118:8202/api";
  baseurlUrl = "https://suzkj.com/api";
} else {
  // baseurlUrl = '/api/admin'
  baseurlUrl = "https://suzkj.com/api";
}
export const baseurl = baseurlUrl
axios.defaults.baseURL = baseurl

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

axios.defaults.transformRequest = [
  function (data, config) {
    // 对 data 进行任意转换处理
    if (config['Content-Type'] === 'multipart/form-data') {
      return data
    }
    return Qs.stringify(data)
  },
]

axios.interceptors.request.use(function (config) {
  let token = window.localStorage.getItem("token1")
  console.log();
  // config.headers.common['Authorization'] = "Bearer " + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZW1iZXJfaWQiOjEsImV4cCI6MTY3OTQ3MDQxMCwiaWF0IjoxNjc2ODc4NDEwLCJpc3MiOiJnYW5idSIsIm5iZiI6MTY3Njg3ODQxMH0.AxGal-THH4UuacXpSfz_G0MdHGtI3y88yPmx9dLvR8o'
  if (token) {
    config.headers.common['Authorization'] = "Bearer " + token
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});


axios.interceptors.response.use(
  response => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据     
    // 否则的话抛出错误
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是2开头的的情况
  // 这里可以跟你们的后台开发人员协商好统一的错误状态码    
  // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
  // 下面列举几个常见的操作，其他需求可自行扩展
  error => {
    console.log(error);
    if (error.response.status) {
      if (error.response.status == 400) {
        // Toast.fail('网络延迟，请刷新该页面')
        // axios
        //   .get('/api/user/check_login_status', {})
        //   .then((res) => {
        //     if (res.data.status == 401) {
        //       router.replace({
        //         path: '/login',
        //         query: {}
        //       });
        //     }
        //   })
      } else if (error.response.status == 401) {
        Toast.clear();
        window.localStorage.removeItem("token1");
        window.localStorage.removeItem("member");
        // message.warning("请先登录");
        // window.location.href = 'http://ganbu.tzgcs360.com/admin/user/login'
        // window.location.href = 'http://localhost:8101/user/login'
        // console.log(error);
        // if (error.response.url != '/apimobile/ceshi_login') {
        //   axios
        //     .post('/apimobile/ceshi_login', {})
        //     .then((res) => {
        //       console.log(res);
        //       window.localStorage.setItem("user", JSON.stringify(res.data.data.user));
        //       if (res.data.status == 1) {
        //         window.localStorage.setItem("token1", res.data.data.token);
        //         location.reload();
        //       }
        //     })
        // }
        if (response.onfig.url != '/web/member') {
          router.replace({
            path: '/login',
            query: {}
          });
        }
      }
      return Promise.reject(error.response);
    }
  })


export function get(url, params = {}, load) {
  if (!load) {
    Toast.loading({
      message: '加载中...',
      duration: 100000,
      forbidClick: true,
    });
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((resp) => {
        resolve(resp.data)
        Toast.clear();
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function post(url, data = {}, load) {
  if (!load) {
    Toast.loading({
      message: '加载中...',
      duration: 100000,
      forbidClick: true,
    });
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url, data)
      .then((resp) => {
        resolve(resp)
        Toast.clear();
      })
      .catch((err) => {
        setTimeout(() => {
          Toast.clear();
        }, 10000);
        reject(err)
      })
  })
}

const upvideoconfig = axios.create({
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

export function uploadfile(url, data) {
  return new Promise((resolve, reject) => {
    upvideoconfig
      .post(url, data)
      .then((resp) => {
        if (resp) resolve(resp)
        else resolve({ data: { status: 0, msg: '请求被终止' } })
      })
      .catch((err) => {
        reject(err)
      })
  })
}
// export function uploadfile(url, data) {
//   return new Promise((resolve, reject) => {
//     upvideoconfig
//       .post(url, data)
//       .then((resp) => {
//         resolve(resp)
//       })
//       .catch((err) => {
//         reject(err)
//       })
//   })
// }
