<template>
  <div>
    <div class="content">
      <div class="cont">
        <div class="txtBox">
          <div class="title">川田</div>
        </div>
        <div class="box">
          <div class="txt">联系我们</div>
          <div class="shu"></div>
          <div class="text">招聘热线:0576-87221221、87232015 档案查询:0576-87232005、87232075</div>
        </div>
      </div>
    </div>
    <div style="height: 41px"></div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.content {
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  .cont {
    width: 1440px;
    font-size: 14px;
    height: 40px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .txtBox {
      display: flex;
      align-items: center;
      .title {
        margin-right: 5px;
      }
      .txt {
        color: #eb0a0a;
        cursor: pointer;
      }
    }
    .box{
      display: flex;
      align-items: center;
      .shu{
        width: 1px;
        height: 15px;
        background-color: #9c9c9c;
        margin: 0 6px;
      }
      .txt{
        cursor: pointer;
      }
    }
  }
}
</style>
