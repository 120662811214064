<template>
  <div>
    <div class="bj">
      <div class="bjContent">
        <div class="bjCont">
          <div class="iconfont icon-close cha" @click="delClick"></div>
          <div class="cont">
            <div class="contHead">发布论坛</div>
            <div class="contBox">
              <div class="name"><span>*</span>论坛标题</div>
              <div class="inpBox">
                <a-input placeholder="请输入标题" v-model="name" />
              </div>
            </div>
            <div class="contBox">
              <div class="name"><span>*</span>主题类型</div>
              <a-select
                :size="size"
                placeholder="请选择您要发布的模块"
                style="width: 100%"
                v-model="lxMrTxt"
                @change="handleChange"
              >
                <a-select-option v-for="item in typeList" :key="item.name">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
            <div class="contBox">
              <div class="name"><span>*</span>内容信息</div>
              <div ref="editorContainer" class="fuwenben"></div>
            </div>
            <div class="contBox">
              <div class="name"><span>*</span>上传图片</div>
              <div class="upImg">
                <div class="imgs">
                  <div
                    class="img"
                    @click="uploadFile"
                    v-if="imgList.length < 9"
                  >
                    <div class="imgBox iconfont icon-camera_fill"></div>
                  </div>
                  <div class="img" v-for="(item, index) in imgList">
                    <img :src="$imgUrl + item.path_name" />
                    <div
                      class="iconfont icon-close close"
                      @click="imgDelClick(index)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="gnBox">
            <div class="btn qx" @click="delClick">取消</div>
            <div class="btn" @click="fabuClick">发布</div>
          </div>
        </div>
      </div>
    </div>
    <input
      type="file"
      ref="fileInput"
      @change="onFileChange"
      style="display: none"
    />
  </div>
</template>
<script>
import WangEditor from "wangeditor";
import { Select, Form, Input, Button, Upload, Icon } from "ant-design-vue";
import QRCode from "qrcode";

export default {
  components: {
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-form": Form,
    "a-form-item": Form.Item,
    "a-input": Input,
    "a-button": Button,
    "a-textarea": Input.TextArea,
    "a-upload": Upload,
    "a-icon": Icon,
  },
  data() {
    return {
      size: "default",
      value: "",
      headers: {
        authorization: "authorization-text",
      },
      form: {
        username: "",
        password: "",
      },
      name: "",
      imgList: [],
      typeList: [],
      lxTxt: "",
      lxMrTxt: "",
      info: {},
    };
  },
  props: ["id"],
  mounted() {
    console.log(this.id);
    if (this.id) {
      this.get();
    } else {
      const editor = new WangEditor(this.$refs.editorContainer);
      editor.create();
      this.editor = editor;
      this.typeGet();
    }
  },
  activated() {},
  methods: {
    get() {
      this.$get("/web/forum_get", {
        id: this.id,
      }).then((res) => {
        this.info = res.data;
        this.name = res.data.name;
        this.imgList = res.data.resources?res.data.resources:[];
        const editor = new WangEditor(this.$refs.editorContainer);
        editor.create();
        this.editor = editor;
        editor.txt.html(res.data.content);
        this.typeGet(res.data.config_id);
        this.lxTxt = res.data.config_id;
      });
    },
    typeGet(id) {
      this.$get("/common/configuration_gets", {
        type: "forum",
      }).then((res) => {
        this.typeList = res.data;
        if (id) {
          for (let i = 0; i < res.data.length; i++) {
            const e = res.data[i];
            if (e.id == id) {
              this.lxMrTxt = e.name;
            }
          }
        }
      });
    },
    imgDelClick(i) {
      this.imgList.splice(i, 1);
    },
    async uploadFile() {
      this.$refs.fileInput.click();
    },
    onFileChange(e) {
      var formData = new FormData();
      console.log(e.target.files);
      formData.append("file", e.target.files[0]);
      formData.append("where", "product");
      this.$uploadfile("/common/fileupload", formData).then((res) => {
        console.log(res);
        if (res.data.status) {
          this.imgList.push(res.data.data);
        } else {
          this.$message.error("网络繁忙,请稍后再试~");
        }
      });
    },
    delClick() {
      this.$emit("show");
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
    handleChange(i) {
      console.log(i);
      for (let j = 0; j < this.typeList.length; j++) {
        const e = this.typeList[j];
        if (e.name == i) {
          this.lxTxt = e.id;
        }
      }
    },
    popupScroll() {
      console.log("popupScroll");
    },
    fabuClick() {
      let data = this.info;
      let content = this.editor.txt.html();
      data.content = content;
      data.name = this.name;
      if (!data.name) {
        this.$message.error("请输入标题");
        return;
      }
      if (!data.content) {
        this.$message.error("请输入内容");
        return;
      }
      let resource = [];
      for (let i = 0; i < this.imgList.length; i++) {
        const e = this.imgList[i];
        resource.push(e.id);
      }
      data.resource = resource.join(",");
      data.config_id = this.lxTxt;
      console.log(data);
      data = JSON.stringify(data);
      this.$post("/web/forum_save", {
        data: data,
      }).then((res) => {
        console.log(res);
        if (res.data.status == 1) {
          this.$message.success("发布成功");
          this.$emit("show", { data: res.data.data });
        } else {
          this.$message.warning(res.data.msg);
        }
      });
      // 处理保存逻辑，可以将content发送到服务器或者进行其他操作
    },
  },
};
</script>
<style lang="less" scoped>
.bjContent {
  display: flex;
  width: 100%;
  justify-content: center;

  .bjCont {
    background-color: #fff;
    width: 1440px;
    margin-top: 90px;
    border-radius: 10px;
    position: relative;

    .cha {
      position: absolute;
      cursor: pointer;
      top: 10px;
      font-weight: 600;
      right: 20px;
      color: #9c9c9c;
    }

    .cont {
      padding: 20px 20px 10px;

      .contHead {
        font-size: 16px;
        font-weight: 600;
        color: #333;
      }

      .contBox {
        padding-top: 0px;

        .name {
          margin-top: 15px;
          margin-bottom: 5px;

          span {
            color: #f30000;
          }
        }

        .tishi {
          line-height: 150%;
          color: #bfbfbf;
          font-size: 12px;
          margin-top: 5px;
          text-align: justify;
        }

        .upImg {
          margin-top: 10px;

          .text {
            margin-left: 20px;
            font-size: 12px;
          }

          .txt {
            margin-left: 5px;
            font-size: 12px;
            color: #999;
          }

          .imgs {
            display: flex;
            margin-top: 15px;
            flex-wrap: wrap;

            .img {
              width: calc(20% - 12px);
              margin-right: 15px;
              padding-top: calc(20% - 12px);
              position: relative;
              margin-bottom: 15px;
              overflow: unset;
              .imgBox {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                border-radius: 5px;
                box-shadow: 0 0 4px 2px #ebebeb;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #9c9c9c;
                font-size: 40px;
                cursor: pointer;
              }
              img {
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 5px;
              }

              .close {
                position: absolute;
                top: -3px;
                right: -3px;
                background-color: #b3b3b3;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 10px;
                color: #fff;
                cursor: pointer;
              }
            }

            .img:nth-child(5n) {
              margin-right: 0;
            }
          }
        }
      }

      .fuwenben {
        margin-top: 5px;
        height: calc(100vh - 800px);
      }

      /deep/.w-e-text-container {
        height: calc(100% - 42px) !important;
      }
    }

    .gnBox {
      border-top: 1px solid #d9d9d9;
      padding: 10px 24px;
      overflow: hidden;
      display: flex;
      justify-content: flex-end;

      .btn {
        color: #fff;
        background: #e23;
        border: 1px solid #e23;
        padding: 5px 15px;
        font-size: 12px;
        border-radius: 5px;
        margin-left: 15px;
        cursor: pointer;
      }

      .qx {
        background: unset;
        border: 1px solid rgb(180, 180, 180);
        color: #666;
      }
    }
  }
}
</style>
